<template>
  <div class="h-full flex flex-col">
    <div v-if="loading" class="px-6 lg:px-12 my-auto">
      <Loader :text="loading"/>
    </div>
    <Error v-else-if="error" :text="error" :show-retry="!!retryCb" @retry="retry()" class="my-auto"/>
    <div v-else class="h-full flex flex-col pt-6 lg:py-10">
      <template v-if="view === 'local'">
        <BSLocal :has-options="hasOptions" @done="localDone($event)" @options="showOptions()"/>
      </template>
      <template v-if="view === 'mono'">
        <BSMono
          :account="account"
          @done="monoDone($event)"
          @failed="handleMonoFailed()"
          @options="showOptions()"
        />
      </template>
      <template v-if="view === 'mbs'">
        <BSMbs
          @done="mbsDone($event)"
          @options="showOptions()"
          @failed="handleMbsFailed()"
          :account="account"
        />
      </template>
      <template v-if="view === 'home'">
        <SectionHeader
          subtitle="Creditclan has requested for your bank statement to process this request, click the button below to continue"
          title="Analyse bank statement"
        />
        <div class="flex mt-10">
          <AppButton @click="start()">Start</AppButton>
        </div>
        <button v-if="fallback" class="text-gray-600 mt-3 text-sm" @click="view = 'local'">Upload manually</button>
      </template>
    </div>
    <Modal v-if="modals.options">
      <div class="md:my-auto">
        <button
          class="w-8 h-8 rounded-full flex justify-center items-center border border-red-500 text-red-500 absolute top-5 right-5 hover:bg-red-50"
          @click="modals.options = false"
        >
          <i class="fa fa-times"></i>
        </button>
        <h3 class="text-lg font-semibold mb-8">Other available methods</h3>
        <div class="flex flex-col space-y-4">
          <div
            v-if="view !== 'mbs' && supportsMbs"
            class="border px-5 py-6 rounded-lg flex items-center cursor-pointer hover:bg-gray-50"
            @click="modals.options = false; view = 'mbs'"
          >
            <div>
              <div class="w-12 text-blue-600">
                <i class="fa fa-sms fa-2x"></i>
              </div>
            </div>
            <div>
              <h6 class="font-medium">Receive PIN from your bank</h6>
              <p class="text-sm mt-1 opacity-70">
                Provide a ticket id and password sent by your bank to authorize us to get your statement
              </p>
            </div>
          </div>
          <div
            v-if="view !== 'mono' && supportsMono"
            class="border px-5 py-6 rounded-lg flex items-center cursor-pointer hover:bg-gray-50"
            @click="modals.options = false; view = 'mono'"
          >
            <div>
              <div class="w-12 text-teal-600">
                <i class="fa fa-mobile-alt fa-2x"></i>
              </div>
            </div>
            <div>
              <h6 class="font-medium">Use internet banking</h6>
              <p class="text-sm mt-1 opacity-70">
                Kindly use your internet or mobile banking credentials to get statement.
              </p>
            </div>
          </div>
          <div
            v-if="view !== 'local'"
            class="border px-5 py-6 rounded-lg flex items-center cursor-pointer hover:bg-gray-50"
            @click="modals.options = false; view = 'local'"
          >
            <div>
              <div class="w-12 text-red-600">
                <i class="fa fa-file-pdf fa-2x"></i>
              </div>
            </div>
            <div>
              <h6 class="font-medium">Upload bank statement file</h6>
              <p class="text-sm mt-1 opacity-70">
                Kindly upload last 7months Pdf statement here.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end mt-auto md:hidden">
        <img src="../../assets/images/savings.svg" alt="" class="w-60"/>
      </div>
    </Modal>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppButton from "@/components/global/Button";
import BSLocal from "@/components/statement/BSLocal";
import BSMono from "@/components/statement/BSMono";
import BSMbs from "@/components/statement/BSMbs";
import mbsBanks from "@/lib/mbs-banks.js";
import Modal from "@/components/global/Modal.vue";
import ajax from "@/mixins/ajax.js";
import Loader from "@/components/global/Loader.vue";
import Error from "@/components/global/Error.vue";
import { delay } from "@/lib/utils.js";

export default {
  name: "BankStatement",
  components: { Error, Loader, Modal, BSMbs, BSMono, BSLocal, AppButton, SectionHeader },
  mixins: [ajax],
  data() {
    return {
      view: 'online-banking',
      has_online_banking: 1,
      fallback: false,
      response: null,
      modals: {
        options: false,
      },
      account: {
        account_name: this.$store.state.statement.payload.account_name,
        account_number: this.$store.state.statement.payload.account_number,
        bank_code: this.$store.state.statement.payload.bank_code,
      }
    }
  },
  created() {
    this.start();
  },
  methods: {
    start() {
      if (this.supportsMbs && !this.isZenithOrGt) this.view = 'mbs';
      else if (this.supportsMono) this.view = 'mono';
      else this.view = 'local';
    },
    showOptions() {
      if (this.hasOptions) this.modals.options = true;
    },
    handleMonoFailed() {
      this.view = 'local';
    },
    handleMbsFailed() {
      if (this.supportsMono) this.view = 'mono';
      else this.view = 'local';
    },
    localDone(transaction_id) {
      this.response = {
        statement_source: 1,
        filename: { transaction_id },
        has_online_banking: false
      };
      this.handleSubmit();
    },
    monoDone(response) {
      this.response = {
        statement_source: 4,
        filename: response,
        has_online_banking: true
      };
      this.handleSubmit();
    },
    mbsDone({ statement_source, transaction_id, ...rest }) {
      this.response = {
        statement_source,
        filename: { transaction_id },
        has_online_banking: false,
        ...rest
      };
      this.handleSubmit();
    },
    async handleSubmit() {
      this.setLoading('It takes less 10 seconds..');
      const analyzed = await this.handleBsSubmit();
      if (analyzed) {
        const done = await this.submitBsReport();
        if (done) return this.$emit('done');
      }
      if (!this.error) {
        this.response = null;
        let message = 'We could not analyze your bank statement, please try again';
        if (this.response?.statement_source === 3) {
          message = 'We could not get your bank statement, please make sure you have funds in your account';
        }
        this.setError(message);
        this.setRetry(() => this.showOptions());
      }
      this.setLoading('');
    },
    async handleBsSubmit(retries = 10) {
      if (retries === 0) return false;
      if (retries < 10) await delay(5000);
      try {
        const { payload: { REQUEST_ID }, token } = this.$store.state.statement;
        if (this.response.statement_source === 1) return true;
        const res = await this.$http.post(`https://mobile.creditclan.com/api/v3/bankstatement/success`, {
          analyze: true, request_id: REQUEST_ID, token, ...this.response
        });
        if (res.data.responsecode === '01') return false;
        if (!res.data.status) return await this.handleBsSubmit(--retries);
        return true;
      } catch (e) {
        const message = e.response ? e.response.data.message : e.message || 'An error occurred, please try again';
        this.setError(message);
        this.setRetry(() => this.finish());
        return false;
      }
    },
    async submitBsReport(retries = 10) {
      if (retries === 0) return false;
      if (retries < 10) await delay(5000);
      try {
        const { bs_token } = this.$store.state.statement;
        const res = await this.$http.post(`/bankstatement/update`, {
          token: bs_token, analyze: true, ...this.response
        });
        if (res.data.responsecode === '01') return false;
        if (!res.data.status) return await this.submitBsReport(--retries);
        return true;
      } catch (e) {
        const message = e.response ? e.response.data.message : e.message || 'An error occurred, please try again';
        this.setError(message);
        this.setRetry(() => this.finish());
        return false;
      }
    },
    cancel() {
      this.view = 'local';
      this.fallback = true;
    }
  },
  computed: {
    supportsMbs() {
      return mbsBanks.some(b => b.sortCode === this.$store.state.statement.payload.bank_code);
    },
    supportsMono() {
      const { payload: { bank_code }, banks_mono } = this.$store.state.statement;
      return !!banks_mono.find(bank => `${ bank.bank_code }` === `${ bank_code }`)?.mono_bank_data;
    },
    hasOptions() {
      return this.supportsMono || this.supportsMbs;
    },
    isZenithOrGt() {
      return this.$store.state.statement.payload.bank_code.match(/057|058/gi);
    }
  },
  watch: {
    view() {
      this.reset();
    }
  }
}
</script>
