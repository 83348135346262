<template>
  <div class="fill-height overflow-hidden md:p-4 lg:p-12 bg-gray-900 bg-opacity-70">
    <div class="grid grid-cols-12 overflow-hidden h-full max-w-7xl mx-auto md:rounded-lg lg:rounded-xl">
      <div
        class="hidden md:flex lg:flex md:col-span-3 lg:col-span-6 bg-blue-600 bg-center bg-white"
        :style="{ background: `linear-gradient(to right, rgb(58 70 168 / 60%), rgb(94 80 151 / 60%)), url(${banner}) center no-repeat`, backgroundSize: 'cover', backgroundColor: '#fff' }"
      />
      <div class="col-span-12 md:col-span-9 lg:col-span-6 flex flex-col bg-white relative overflow-hidden">
        <img
          src="@/assets/images/pattern-up.png" alt="background pattern"
          class="absolute top-0 left-0 hidden md:block"
          style="width: 300px;"
        />
        <img
          src="@/assets/images/pattern-down.png" alt="background pattern"
          class="absolute bottom-0 right-0 hidden md:block"
          style="width: 300px"
        />
        <div class="h-full overflow-y-auto flex flex-col z-50">
          <template v-if="!cancelling">
            <Preload v-if="preload" @done="preload = false"/>
            <template v-else>
              <div v-if="loading" class="px-6 pt-6 lg:pt-10 lg:px-12 my-auto">
                <Loader
                  v-if="loading === 'init'" variant="skateboard"
                  :texts="['Just a moment..', 'Setting things up..']" :timeout="2000"
                />
                <Loader v-else variant="skateboard" :text="loading"/>
              </div>
              <div v-else-if="error" class="px-6 pt-6 lg:pt-10 lg:px-12 my-auto">
                <Error :text="error" show-retry @retry="init()"/>
              </div>
              <Analysis v-else-if="$store.state.eligibility.profile.user_id"/>
            </template>
          </template>
          <div v-else class="h-full flex flex-col md:h-auto md:my-auto px-6 pt-6 lg:pt-10 lg:px-12">
            <SectionHeader title="Are you sure you want to cancel this request?" class="mt-12 md:mt-0"/>
            <div class="flex">
              <AppButton @click="cancelling = false">No</AppButton>
              <button
                @click="cancel()"
                class="py-1 px-3 border border-red-700 rounded-lg text-red-700 text-sm ml-2"
              >
                Yes, cancel
              </button>
            </div>
            <div class="flex justify-end mt-auto md:hidden">
              <img src="../assets/images/departing.svg" alt="" class="w-60"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Analysis from "@/components/core/Analysis.vue";
import banks from "@/lib/banks";
import ajax from "@/mixins/ajax";
import Error from "@/components/global/Error";
import Preload from "@/components/sections/Preload";
import { decodeToken, roundToNearestTen } from "@/lib/utils";
import SectionHeader from "@/components/global/SectionHeader";
import AppButton from "@/components/global/Button";
import axios from "axios";
import Loader from "@/components/global/Loader.vue";

export default {
  components: { Loader, AppButton, SectionHeader, Preload, Error, Analysis },
  mixins: [ajax],
  data() {
    return {
      loading: '',
      banner: '',
      data: null,
      preload: false,
      cancelling: false
    }
  },
  created() {
    this.setup();
    this.$bus.$on('cancel', () => {
      this.cancelling = true;
    });
  },
  mounted() {
    parent.postMessage({ id: 'cclan-frame-loaded' }, '*');
  },
  methods: {
    setup() {
      const { token } = this.$route.query;
      if (!token) return this.setError('Something went wrong');
      const decoded = decodeToken(token);
      const {
        request,
        profile,
        request_id,
        home_address = { address: '', state: '', lga: '' },
        identity = { nin: '' },
        config: {
          tokenize_card = false,
          analyze_bank_statement = false,
          no_frequently_called_numbers = 0,
          show_offers = false,
          show_questions = false,
          show_bank_account = false,
          match_account_with_bvn = false,
          show_work_information = false,
          notify_whatsapp = false,
          show_signature = false,
          scan_whatsapp = false,
          platform = 'shop',
          show_nin = false,
          bs_name_match = false,
          account_name_match = false,
          recommended_upfront_cap = false,
          show_consent = false,
          always_show_offers = false,
          show_address = false,
          show_profile = false,
          first_month_upfront = false,
          single_offer = false,
          can_change_repayment_frequency = true,
          show_income = false,
          show_nok = false,
          modify_offer_beyond_limits = false,
          can_modify_offer = false,
          verify_work_email = false,
          show_picture = true,
          return_offer = false,
        } = {},
        extra = {},
        banner,
        lender,
        email_verified = false,
        work = {},
        source = '',
        stage = null,
      } = decoded;
      this.banner = banner || 'https://images.unsplash.com/photo-1572666341285-c8cb9790ca50?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80';
      this.$store.commit('eligibility/patch', {
        request: { ...request, tenor_type: request?.tenor_type || 2 },
        profile: { ...profile, email: profile.email?.replace(/ /gi, '') },
        identity, home_address, request_id, extra, lender, email_verified, work, source, stage
      });
      this.$store.commit('eligibility/config', {
        tokenize_card,
        analyze_bank_statement,
        no_frequently_called_numbers,
        show_offers,
        show_questions,
        show_bank_account,
        match_account_with_bvn,
        show_work_information,
        notify_whatsapp,
        show_signature,
        verify_email: false,
        platform,
        scan_whatsapp,
        show_nin,
        bs_name_match,
        account_name_match,
        recommended_upfront_cap,
        show_consent,
        always_show_offers,
        show_address,
        show_profile,
        first_month_upfront,
        single_offer,
        can_change_repayment_frequency,
        show_income,
        show_nok,
        modify_offer_beyond_limits,
        can_modify_offer,
        verify_work_email,
        show_picture,
        return_offer,
      });
      this.data = {
        profile: {
          ...profile, email: profile.email?.replace(/ /gi, ''),
          ...home_address, email_verified
        },
        request: { ...request, tenor_type: request?.tenor_type || 2 }
      };
      this.init();
      this.preload = true;
    },
    async init() {
      try {
        this.setLoading('init');
        let data = {};
        data = await this.checkUser(this.data.profile.email, this.data.profile.phone);
        if (!data.user_id && !this.error) data = await this.createUser(this.data.profile);
        if (data.user_id && data.token) {
          if (this.$mixpanel.get_distinct_id() !== data.user_id) {
            this.$mixpanel.identify(data.user_id);
            this.$mixpanel.people.set({
              name: this.data.profile.full_name,
              email: this.data.profile.email,
              $email: this.data.profile.email,
            });
          }
          this.$store.commit('eligibility/patch', { token: data.token });
          this.$store.commit('eligibility/profile', { user_id: data.user_id, ...this.data.profile });
          await this.getUserDetails(data.token);
          this.$store.commit('eligibility/patch', { request: this.data.request });
          if (!this.$store.state.eligibility.has_data) this.$store.dispatch('eligibility/postData', { type: 'phone' });
          if (this.$store.state.eligibility.config.show_questions) await this.getRandomQuestions();
          await this.getCreditclanRequestId();
        }
        this.setLoading('');
      } catch (e) {
        console.log(e);
        this.setError('An error occurred, please try again!', true)
      }
    },
    async getCreditclanRequestId() {
      const { config: { platform }, extra, request_id } = this.$store.state.eligibility;
      if (!request_id) {
        if (platform === 'shop-power') await this.getShopMerchantCcId(extra.power_merchant_request_id);
        if (platform === 'shop-merchant') await this.getShopMerchantCcId(extra.merchant_request_id || extra.power_merchant_request_id);
        if (platform === 'shop-global') await this.getShopGlobalCcId(extra.global_request_id);
        if (platform === 'rent-tenant') await this.getRentCcId(extra.rent_id);
        if (platform === 'school-parent') await this.getParentCcId(extra.parent_request_id);
        if (platform === 'school-teacher') await this.getTeacherCcId(extra.teacher_request_id);
        if (platform === 'merchant') await this.getMerchantCcId(extra.merchant_request_id);
      }
      if (this.$store.state.eligibility.request_id) {
        await this.getRequestDetails();
        this.$mixpanel.track('Continuing eligibility', {
          platform,
          request_id: this.$store.state.eligibility.request_id
        });
        return;
      }
      this.$mixpanel.track('Started eligibility', { platform });
    },
    async getRequestDetails() {
      try {
        const {
          token,
          request_id,
          config: { always_show_offers, first_month_upfront }
        } = this.$store.state.eligibility;
        const res = await this.$http.post('https://mobile.creditclan.com/api/v3/loan/details', {
          token, request_id
        });
        const { stage, offers, offer_selected } = res.data.data.loan;
        if (offer_selected) {
          const payment = { amount: offer_selected.upfront, upfront: true };
          return this.$store.commit('eligibility/patch', { stage, payment, offer: offer_selected });
        }
        let [offer] = offers ?? [];
        if (offer) {
          offer = {
            ...offer,
            upfront: roundToNearestTen(+(`${ offer.upfront }`.replace(/,/gi, ''))),
            amount: roundToNearestTen(+(`${ offer.amount }`.replace(/,/gi, ''))),
            monthly_repayment: roundToNearestTen(+(`${ offer.monthly_repayment }`.replace(/,/gi, ''))),
          };
          if (always_show_offers && !offer.amount) {
            const price = offer.amount + offer.upfront;
            const upfront = roundToNearestTen(price * .5);
            const total_repayment = price - upfront;
            const duration = 3;
            offer = {
              ...offer,
              upfront,
              amount: total_repayment,
              total_repayment,
              monthly_repayment: roundToNearestTen(total_repayment / duration),
              duration,
            }
          }
          if (first_month_upfront) {
            const total = offer.amount + offer.upfront;
            const duration = offer.duration;
            const monthly_repayment = roundToNearestTen(total / duration);
            const upfront = monthly_repayment;
            const amount = total - upfront;
            offer = {
              ...offer,
              upfront,
              monthly_repayment,
              total_repayment: amount,
              amount,
              duration: duration - 1,
            }
          }
        }
        this.$store.commit('eligibility/patch', { stage, offer });
      } catch (e) {
        this.$store.commit('eligibility/patch', { request_id: null });
        console.log(e);
      }
    },
    async getShopMerchantCcId(merchant_request_id) {
      if (!merchant_request_id) return;
      try {
        const res = await axios.get(`https://sellbackend.creditclan.com/merchantclanbackend/public/index.php/api/request_only/${ merchant_request_id }`);
        const { creditclan_request_id } = res.data.data;
        this.$store.commit('eligibility/patch', { request_id: creditclan_request_id });
      } catch (e) {
        console.log({ e })
      }
    },
    async getShopGlobalCcId(global_request_id) {
      if (!global_request_id) return;
      try {
        const res = await axios.get('https://sellbackend.creditclan.com/merchantclan/public/index.php/api/request/check', {
          params: { global_request_id }
        });
        const { creditclan_request_id } = res.data.data;
        this.$store.commit('eligibility/patch', { request_id: creditclan_request_id });
      } catch (e) {
        console.log({ e })
      }
    },
    async getRentCcId(rent_id) {
      if (!rent_id) return;
      try {
        const res = await axios.post('https://wema.creditclan.com/api/v3/rent/get_cc_request', { rent_id });
        const { creditclan_request_id } = res.data.data;
        this.$store.commit('eligibility/patch', { request_id: creditclan_request_id });
      } catch (e) {
        console.log(e);
      }
    },
    async getParentCcId(parent_request_id) {
      if (!parent_request_id) return;
      try {
        const res = await axios.post('https://sellbackend.creditclan.com/parent/index.php/external/get_cc_request', { parent_request_id });
        const { creditclan_request_id } = res.data.data;
        this.$store.commit('eligibility/patch', { request_id: creditclan_request_id });
      } catch (e) {
        console.log(e);
      }
    },
    async getTeacherCcId(teacher_request_id) {
      if (teacher_request_id) return;
      try {
        const res = await axios.post('https://sellbackend.creditclan.com/parent/index.php/external/get_cc_request', { teacher_request_id });
        const { creditclan_request_id } = res.data.data;
        this.$store.commit('eligibility/patch', { request_id: creditclan_request_id });
      } catch (e) {
        console.log(e);
      }
    },
    async getMerchantCcId(merchant_request_id) {
      if (!merchant_request_id) return;
      try {
        const res = await axios.post('https://sellbackend.creditclan.com/parent/index.php/external/get_cc_request', { merchant_request_id });
        const { creditclan_request_id } = res.data.data;
        this.$store.commit('eligibility/patch', { request_id: creditclan_request_id });
      } catch (e) {
        console.log(e);
      }
    },
    async getRandomQuestions() {
      const { token, request_id } = this.$store.state.eligibility;
      const res = await this.$http.post('/customer/getrandomquestions', {
        token, request_id
      });
      this.$store.commit('eligibility/patch', { questions: res.data.data.questions });
    },
    async checkUser(email, phone) {
      try {
        const { data } = await this.$http.post(`/customer/check/details`, { email, phone });
        const { token, user_id } = data;
        return { token, user_id };
      } catch (e) {
        this.setError('An error occurred, please try again!');
        return false;
      }
    },
    async createUser(data) {
      const { email_verified = false, ...rest } = data;
      try {
        const payload = {
          profile: { ...rest, password: 'temp', is_individual: 1 }
        };
        if (email_verified) payload.email_verified = true;
        const res = await this.$http.post('/customer/register/market_user', payload);
        this.$store.commit('eligibility/patch', { new_customer: true });
        const { token, user_id } = res.data;
        return { token, user_id };
      } catch (e) {
        this.setError('An error occurred, please try again!');
        return false;
      }
    },
    async getUserDetails(token) {
      try {
        const res = await this.$http.post('/user/detailsbyid', { token });
        const {
          profile: { phone, legal_name: full_name, email, date_of_birth, gender, file_name = '' },
          home_address: { home_address, home_state, home_landmark },
          bvn: { bvn },
          work: {
            net_monthly_income,
            company_name,
            work_sector,
            start_month,
            start_year,
            occupation_id,
            work_address,
            official_email,
            work_email_verified,
            work_state_id,
            work_lga_id,
          },
          next_of_kin: { nok_name, nok_email, nok_phone, nok_address, nok_relationship },
          accounts
        } = res.data.data.userData.data;
        let profile_image = this.$store.state.eligibility.profile.profile_image || file_name;
        if (profile_image === 'https://res.cloudinary.com/du1mqyww8/image/upload/v1617623098/wnwad3hebiais8ulr5yy.png') profile_image = null;
        if (profile_image === 'https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg') profile_image = null;
        const { has_data, last_application_date, frequently_called_numbers: fcn, data, whatsapp_id } = res.data;
        this.$store.commit('eligibility/profile', {
          phone,
          full_name,
          email: email?.toLowerCase().trim(),
          date_of_birth,
          gender,
          profile_image
        });
        this.$store.commit('eligibility/account', { bvn });
        const frequently_called_numbers = fcn || [{ phone: '' }, { phone: '' }];
        this.$store.commit('eligibility/patch', {
          work: {
            monthly_income: net_monthly_income,
            company_name,
            work_sector,
            start_month,
            start_year,
            occupation_id,
            address: work_address,
            official_email,
            work_email_verified,
            work_state_id,
            work_lga_id
          },
          next_of_kin: {
            full_name: nok_name,
            email: nok_email,
            phone: nok_phone,
            address: nok_address,
            relationship: nok_relationship
          },
          has_data, last_application_date, frequently_called_numbers,
          email_verified: data.validations.email,
          whatsapp_id,
        });
        if (home_address) this.$store.commit('eligibility/home_address', {
          address: home_address, state_id: home_state, home_landmark
        });
        if ((accounts || []).length === 0) this.$store.commit('eligibility/patch', { new_customer: true });
        else {
          const {
            bank_id, card_name: account_name, last_four_digits: account_number, okra_id, account_card_id,
          } = accounts.filter(a => !!a.bank_id)?.pop() || {};
          const bank_code = banks.find(bank => bank.id === bank_id)?.bank_code;
          const account = { bank_id, account_name, account_number, bvn, bank_code, account_card_id, okra_id };
          this.$store.commit('eligibility/account', { ...account });
        }
      } catch (e) {
        this.setError('Failed to get user details, please try again');
      }
    },
    cancel() {
      parent.postMessage({ id: 'cclan-cancel' }, "*");
    },
  }
}
</script>
