<template>
  <div class="px-6 lg:px-12 pb-8">
    <div v-if="overlay">
      <RandomQuestions @done="overlay = ''"/>
    </div>
    <div v-else>
      <template v-if="loading">
        <Loader v-if="loading === 'checking'" text="Checking NIN number.."/>
        <Loader v-else-if="loading === 'confirming'" text="Confirming your NIN.."/>
        <Loader v-else-if="loading === 'hold-up'" text="Crossing ojuelegba bridge, no cars are stopping...pim..pim"/>
        <Loader v-else :text="loading"/>
      </template>
      <template v-else>
        <template v-if="currentView === 'default'">
          <SectionHeader
            title="Kindly provide your NIN"
            subtitle="Dial *346# to get your NIN number"
          />
          <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
          <AppInput type="number" v-model="number" placeholder="Enter NIN number"/>
          <div v-if="number.length && number.length !== 11" class="text-red-400 text-sm mt-2">
            Nin must be 11 digits
          </div>
          <div class="flex mt-12">
            <AppButton @click="submit()" :disabled="number.length !== 11">Continue</AppButton>
          </div>
          <p @click="$emit('done')" class="mt-8 text-gray-600 cursor-pointer">Skip</p>
        </template>
        <template v-if="currentView === 'verify'">
          <SectionHeader
            :title="'Verify NIN number'"
            :subtitle="`A verification code has been sent to the email address registered with the NIN number ${number}, enter code below to verify`"
          />
          <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
          <AppInput v-model="code" placeholder="Enter code"/>
          <div class="flex mt-12">
            <AppButton @click="verifyCode()" :disabled="!code">Continue</AppButton>
          </div>
          <p @click="change()" class="mt-4 text-gray-500 cursor-pointer">Change NIN number</p>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader"
import AppButton from "@/components/global/Button";
import AppInput from "@/components/global/Input";
import Message from "@/components/global/Message";
import Loader from "@/components/global/Loader";
import { namesMatch } from "@/lib/utils";
import view from '@/mixins/view';
import ajax from "@/mixins/ajax";
import RandomQuestions from "@/components/sections/RandomQuestions";

export default {
  name: 'NationalIdentityNumber',
  components: { RandomQuestions, Loader, Message, AppInput, AppButton, SectionHeader },
  mixins: [view, ajax],
  data() {
    return {
      number: '',
      code: '',
      currentView: 'default',
      tries: 3
    }
  },
  async created() {
    const { config: { show_nin }, identity = {}, has_data } = this.$store.state.eligibility;
    if (!show_nin || !has_data) return this.$emit('skip');
    await this.checkDataGotten();
    if (identity.nin && identity.nin.length === 11) {
      this.number = identity.nin;
      await this.submit();
    }
  },
  methods: {
    async checkDataGotten() {
      this.overlay = 'random-questions';
      try {
        this.setLoading('Please wait');
        const { data } = await this.$http.post('/customer/checkdata', { token: this.$store.state.eligibility.token });
        this.$store.commit('eligibility/patch', { has_data: data.data.has_data });
        if (data.data.has_data) return this.$emit('skip');
      } catch (e) {
        this.setError('An error occurred, please try again!');
      }
      this.setLoading('');
    },
    async submit() {
      if (!this.tries) return this.setError('You have exhausted your number of tries, skip this section to continue', true);
      this.error = '';
      if (!this.number) return;
      this.overlay = 'random-questions';
      this.setLoading('checking');
      setTimeout(() => {
        if (this.loading === 'confirming') this.setLoading('hold-up');
      }, 5000);
      try {
        const res = await this.$store.dispatch('eligibility/postData', { type: 'nin', nin: this.number });
        if (res.error_code === '01') this.currentView = 'verify';
        else await this.checkData();
        this.tries--;
      } catch (e) {
        this.setError('An error occurred, please try again');
      }
      this.setLoading('');
    },
    async checkData() {
      this.setLoading('confirming');
      try {
        const { data } = await this.$http.post('/customer/checkdata', { token: this.$store.state.eligibility.token });
        if (!data.data.has_data) {
          return this.setError(`We could not get any data with this NIN, please make sure NIN is correct. You have ${ this.tries } tries left`, true);
        }
        if (!namesMatch(this.$store.state.eligibility.profile.full_name, data.data.name)) {
          return this.setError(`Looks like this NIN belongs to someone else, please try again with an NIN that belongs to you. You have ${ this.tries } tries left`, true);
        }
        this.$store.commit('eligibility/patch', { has_data: data.data.has_data });
        return this.next();
      } catch (e) {
        this.setError(`An error occurred, please try again. You have ${ this.tries } tries left`);
      }
      this.setLoading('');
    },
    async verifyCode() {
      this.overlay = 'random-questions';
      this.setLoading('Please wait..');
      try {
        const { data } = await this.$http.post('/customer/validate_email_otp', {
          otp: this.code,
          token: this.$store.state.eligibility.token
        });
        if (!data.status) return this.setError(data.message);
        await this.checkData();
        this.currentView = 'default';
      } catch (e) {
        this.setError('An error occurred, please try again');
      }
      this.setLoading('');
      this.code = '';
    },
    next() {
      this.$emit('done')
    },
    change() {
      this.currentView = 'default'
    },
    getOccupation() {
      const rand = Math.floor(Math.random() * 10) + 1;
      return ['banker', 'doctor', 'lawyer', 'software developer', 'business owner', 'carpenter', 'nurse', 'laborer', 'administrative assistant', 'mechanic', 'journalist'][rand];
    }
  }
}
</script>
