<template>
  <div>
    <label
      for="file"
      class="flex justify-center items-center w-32 h-32 rounded-full border border-yellow-200 relative cursor-pointer hover:bg-yellow-100 bg-yellow-50"
    >
      <img
        v-if="this.$store.state.eligibility.profile.profile_image"
        :src="this.$store.state.eligibility.profile.profile_image"
        class="w-full h-full object-cover rounded-full" alt=""
      />
      <i v-else class="fa fa-plus fa-2x text-yellow-600"></i>
      <input type="file" id="file" hidden @change="loadImage" accept="image/png,image/jpeg,image/jpg"/>
    </label>
    <Modal v-if="cropper.visible" full>
      <div class="my-auto">
        <cropper
          class="h-72"
          :src="cropper.image"
          :stencil-props="{ aspectRatio: 1 }"
          @change="change"
        />
        <div class="flex justify-center mt-10">
          <AppButton :loading="uploading" @click="upload()" :disabled="uploading">Upload</AppButton>
          <button
            @click="reset()" :disabled="uploading"
            class="py-1 px-3 border border-red-500 text-red-500 rounded-lg ml-2"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/global/Modal";
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import AppButton from "@/components/global/Button";

export default {
  name: "Avatar",
  components: { AppButton, Modal, Cropper },
  data() {
    return {
      cropper: {
        visible: false,
        image: null,
        canvas: null
      },
      uploading: false
    };
  },
  methods: {
    loadImage(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.cropper.image = e.target.result;
          this.cropper.visible = true;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    change({ canvas }) {
      this.cropper.canvas = canvas
    },
    reset() {
      this.cropper.visible = false;
      this.cropper.image = null;
      this.cropper.canvas = null;
    },
    upload() {
      this.uploading = true;
      this.cropper.canvas.toBlob(async blob => {
        if (blob) await this.save(blob);
        else this.uploading = false;
      }, 'image/jpeg');
    },
    async save(blob) {
      try {
        const fd = new FormData();
        fd.append('file', blob, 'avatar.jpeg');
        fd.append('token', this.$store.state.eligibility.token);
        const { data } = await this.$http.post('/customer/upload/picture', fd, { headers: { 'Content-Type': 'multipart/form-data' } });
        if (data.status) {
          this.$store.commit('eligibility/profile', { profile_image: data.data.filename })
          this.$emit('done');
          this.reset();
        }
      } catch (e) {
        console.log(e);
      }
      this.uploading = false;
    },
  },
}
</script>
