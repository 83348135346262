<template>
  <div class="h-full flex flex-col">
    <Loader v-if="loading" :text="loading" class="my-auto px-6 lg:px-12 pb-8"/>
    <div v-else class="h-full flex flex-col">
      <div v-if="!document" class="px-6 lg:px-12 pb-8">
        <SectionHeader
          title="Work Identity"
          :subtitle="`Kindly upload a copy of your staff id card at ${work.company_name}`"
        />
        <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
        <label
          class="border-2 border-dashed rounded-xl w-full flex cursor-pointer overflow-hidden px-5 py-4 relative mt-4 hover:bg-gray-50"
          :class="{'p-0 w-48 h-36': preview}"
        >
          <i class="flex items-center justify-center">
            <i class="fa fa-upload text-yellow-400 mr-3"></i>Upload work id
          </i>
          <input
            type="file" name="file" id="file" hidden @change="handleFileChange"
            accept="image/png,image/jpeg,image/jpg"
          />
        </label>
        <Modal v-if="cropper.visible" full>
          <div class="my-auto">
            <cropper
              class="h-72"
              :src="cropper.image"
              :stencil-props="{ aspectRatio: 16 / 10 }"
              @change="change"
            />
            <div class="flex justify-center mt-10">
              <AppButton :loading="uploading" @click="upload()" :disabled="uploading">Upload</AppButton>
              <button
                @click="reset()"
                class="py-1 px-3 border border-red-500 text-red-500 rounded-lg ml-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
      <div v-else class="px-6 lg:px-12 pb-8">
        <SectionHeader
          title="Work Identity"
        />
        <img :src="document.url" class="w-full max-w-4xl border rounded-lg" :alt="document.type"/>
        <div class="flex items-center mt-8">
          <AppButton @click="next()">Continue</AppButton>
          <button @click="handleChange()" class="py-1 px-3 text-gray-700 rounded-lg ml-3">Change</button>
        </div>
      </div>
      <div class="flex justify-end mt-auto md:hidden">
        <img src="../../assets/images/whatsapp-phone-half.png" alt="" class="w-52"/>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppButton from "@/components/global/Button";
import Loader from "@/components/global/Loader";
import ajax from "@/mixins/ajax";
import Modal from "@/components/global/Modal.vue";
import { Cropper } from 'vue-advanced-cropper';
import Message from "@/components/global/Message.vue";

export default {
  name: "WorkIdentity",
  components: { Message, Modal, Loader, AppButton, SectionHeader, Cropper },
  mixins: [ajax],
  data() {
    return {
      file: null,
      preview: null,
      work: this.$store.state.eligibility.work,
      cropper: {
        visible: false,
        image: null,
        canvas: null
      },
      uploading: false,
    }
  },
  computed: {
    document() {
      const documents = this.$store.state.eligibility.documents;
      return documents.find(doc => doc.type === 'work-id');
    }
  },
  created() {
    const { work, config: { show_work_information } } = this.$store.state.eligibility;
    if (!show_work_information || work.occupation_id !== '1') return this.$emit('skip');
  },
  methods: {
    async handleFileChange(e) {
      this.file = e.target.files[0];
      this.cropper.image = await this.convertToBase64(this.file);
      this.cropper.visible = true;
    },
    change({ canvas }) {
      this.cropper.canvas = canvas
    },
    reset() {
      this.cropper.visible = false;
      this.cropper.image = null;
      this.cropper.canvas = null;
    },
    upload() {
      this.setLoading('Uploading work identity..');
      this.cropper.canvas.toBlob(async blob => {
        if (blob) {
          await this.submit(blob);
          this.cropper.visible = false;
        } else this.setLoading('');
      }, 'image/jpeg');
    },
    async submit(blob) {
      if (!blob) return;
      try {
        const fd = new FormData();
        fd.append('file', blob, 'work-id.jpeg');
        const { data } = await this.$http.post('/customer/upload/picture', fd, { headers: { 'Content-Type': 'multipart/form-data' } });
        if (data.status) {
          const documents = [{ type: 'work-id', url: data.data.filename }];
          this.$store.commit('eligibility/patch', { documents });
          this.$emit('done');
          this.reset();
        }
      } catch (e) {
        console.log(e);
      }
      this.setLoading('');
    },
    next() {
      this.$emit('done');
    },
    handleChange() {
      const documents = this.$store.state.eligibility.documents.filter(doc => doc.type !== 'work-id');
      this.$store.commit('eligibility/patch', { documents });
    },
    convertToBase64(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => resolve(reader.result), false);
        if (file) reader.readAsDataURL(file);
      })
    },
  },
}
</script>
