<template>
  <div class="h-full flex flex-col md:h-auto md:my-auto">
    <div class="px-6 pt-8 lg:pt-10 lg:px-12">
      <img :src="$store.state.statement.payload.logo" alt="logo" class="w-20 rounded-full mb-10 border">
      <SectionHeader
        :title="`${$store.state.statement.payload.name || 'Our partners'} has requested for your bank statement`"
      />
      <div class="border bg-gray-50 rounded p-4 mt-8">
        <div class="text-gray-700 text-sm mb-1">{{ $store.getters['statement/bankName'] }}</div>
        <div class="text-gray-400 flex items-center">
          Account number:
          <div class="font-semibold text-gray-700 ml-2">
            {{ $store.state.statement.payload.account_number }}
          </div>
        </div>
        <div class="text-gray-400 flex items-center">
          Account name:
          <div class="font-semibold text-gray-700 ml-2">
            {{ $store.state.statement.payload.account_name }}
          </div>
        </div>
      </div>
      <AppButton @click="$emit('done')" class="mt-10">Continue</AppButton>
    </div>
    <div class="flex justify-end mt-auto md:hidden">
      <img src="../../assets/images/savings.svg" alt="" class="w-60"/>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader.vue";
import AppButton from "@/components/global/Button.vue";

export default {
  name: "Intro",
  components: { AppButton, SectionHeader },
}
</script>
