<template>
  <div class="px-6 lg:px-12 pb-8">
    <SectionHeader
      title="Where do you work?"
      subtitle="Please fill in all fields correctly"
    />
    <form>
      <ValidationObserver ref="observer">
        <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
        <div class="flex flex-col space-y-4">
          <ValidationProvider name="occupation_id" rules="required" v-slot="{ errors }">
            <label class="input-label-color flex mb-2 text-gray-600">Employment status</label>
            <AppSelect v-model="values.occupation_id" :errors="errors" class="w-full">
              <option disabled selected>Select employment status</option>
              <option :value="status.value" v-for="status in occupations" :key="status.value">
                {{ status.text }}
              </option>
            </AppSelect>
          </ValidationProvider>
          <template v-if="values.occupation_id === '1'">
            <ValidationProvider name="Place of work" rules="required" v-slot="{ errors }">
              <label class="input-label-color flex mb-2 text-gray-600">Place of work</label>
              <AppInput
                v-model="values.company_name" type="text" :errors="errors" class="w-full"
              />
            </ValidationProvider>
            <ValidationProvider name="work_sector" rules="required" v-slot="{ errors }">
              <label class="input-label-color flex mb-2 text-gray-600">Work sector</label>
              <AppSelect v-model="values.work_sector" :errors="errors" class="w-full">
                <option disabled selected>Select work sector</option>
                <option :value="sector.value" v-for="sector in sectors" :key="sector.value">
                  {{ sector.text }}

                </option>
              </AppSelect>
            </ValidationProvider>
            <ValidationProvider name="address" rules="required" v-slot="{ errors }">
              <label class="input-label-color flex mb-2 text-gray-600">Company address</label>
              <AppTextArea v-model="values.address" :errors="errors"/>
            </ValidationProvider>
            <ValidationProvider name="work_state" rules="required" v-slot="{ errors }">
              <label class="input-label-color flex mb-2 text-gray-600">Work state</label>
              <AppSelect v-model="values.work_state" :errors="errors">
                <option disabled value="">Select work state</option>
                <option v-if="!states.length" value="" disabled>Loading</option>
                <option v-for="state in states" :key="state.value" :value="state.value">
                  {{ state.text }}
                </option>
              </AppSelect>
            </ValidationProvider>
            <ValidationProvider v-if="!no_work_email" name="official_email" rules="required|email" v-slot="{ errors }">
              <label class="input-label-color flex mb-2 text-gray-600">Official email</label>
              <AppInput type="email" v-model="values.official_email" :errors="errors"/>
            </ValidationProvider>
            <AppCheckbox v-model="no_work_email" class="mt-4">I don't have access to my work email</AppCheckbox>
          </template>
          <template
            v-if="values.occupation_id === '2' || values.occupation_id === '4' || values.occupation_id === '5'"
          >
            <ValidationProvider name="Business name" rules="required" v-slot="{ errors }">
              <label class="input-label-color flex mb-2 text-gray-600">Business name</label>
              <AppInput
                v-model="values.company_name" type="text" :errors="errors" class="w-full"
              />
            </ValidationProvider>
            <ValidationProvider name="Business sector" rules="required" v-slot="{ errors }">
              <label class="input-label-color flex mb-2 text-gray-600">Business sector</label>
              <AppSelect v-model="values.work_sector" :errors="errors" class="w-full">
                <option disabled value="">Select business sector</option>
                <option :value="sector.value" v-for="sector in sectors" :key="sector.value">
                  {{ sector.text }}
                </option>
              </AppSelect>
            </ValidationProvider>
            <ValidationProvider name="address" rules="required" v-slot="{ errors }">
              <label class="input-label-color flex mb-2 text-gray-600">Business address</label>
              <AppTextArea v-model="values.address" :errors="errors"/>
            </ValidationProvider>
            <ValidationProvider name="directors" rules="required" v-slot="{ errors }">
              <label class="input-label-color flex mb-2 text-gray-600">No. of directors</label>
              <AppInput type="number" v-model="values.directors" :errors="errors"/>
            </ValidationProvider>
          </template>
        </div>

        <div class="flex mt-10">
          <AppButton @click="submit()">Next</AppButton>
        </div>
      </ValidationObserver>
    </form>

    <Modal v-if="modal === 'no-work-email'">
      <div class="h-full flex flex-col justify-center items-center text-center">
        <i class="fa fa-exclamation-circle text-red-500 fa-4x"></i>
        <h3 class="text-2xl mt-8 font-medium">Unable to verify work email</h3>
        <p class="text-base mt-4">
          Fulfilment will take up to 48 hours if work email is not validated. if you wish to pick up item immediately,
          check the checkbox below.
        </p>
        <AppCheckbox v-model="instant_fulfilment" class="mt-4">Enable instant fulfilment</AppCheckbox>
        <div class="flex items-center mt-10 space-x-4">
          <AppButton @click="no_work_email = false; modal = ''" variant="outline" size="sm">
            <i class="fa fa-arrow-left mr-4"></i>
            Add work email
          </AppButton>
          <AppButton @click="next()" size="sm">Continue</AppButton>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppSelect from "@/components/global/Select";
import AppInput from "@/components/global/Input";
import AppTextArea from "@/components/global/TextArea";
import AppButton from "@/components/global/Button";
import states from "@/lib/states.js";
import domains from "@/lib/domains.js";
import { months, occupations, sectors, years } from "@/lib/options.js";
import Message from "@/components/global/Message.vue";
import AppCheckbox from "@/components/global/AppCheckbox.vue";
import Modal from "@/components/global/Modal.vue";

export default {
  name: "WorkInformation",
  components: { Modal, AppCheckbox, Message, AppButton, AppTextArea, AppInput, AppSelect, SectionHeader },
  data() {
    return {
      values: {
        work_sector: '',
        occupation_id: '',
        address: '',
        start_month: '',
        start_year: '',
        company_name: '',
        official_email: '',
        directors: '',
        work_state: '',
      },
      sectors,
      months,
      years,
      occupations,
      states,
      error: '',
      no_work_email: false,
      instant_fulfilment: false,
      modal: '',
    }
  },
  created() {
    const { show_work_information } = this.$store.state.eligibility.config;
    if (!show_work_information) return this.$emit('skip');
    this.values = this.$store.state.eligibility.work || {};
  },
  watch: {
    no_work_email(v) {
      if (v) this.values.official_email = '';
    }
  },
  methods: {
    async submit() {
      if (!(await this.$refs.observer.validate())) return;
      const { occupation_id, official_email } = this.values;
      if (occupation_id === '1') {
        if (official_email && domains.includes(official_email.split('@')[1])) {
          this.error = 'Please enter a valid work email';
          return;
        }
        if (!official_email) {
          this.modal = 'no-work-email';
          return;
        }
      }
      this.next();
    },
    next() {
      this.$store.commit("eligibility/work", this.values);
      this.$store.commit("eligibility/patch", { instant_fulfilment: this.instant_fulfilment });
      this.$emit("done");
    }
  }
}
</script>
