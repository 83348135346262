<template>
  <div class="h-full flex flex-col md:h-auto md:my-auto px-6 pt-6 lg:pt-10 lg:px-12">
    <SectionHeader title="Are you sure you want to cancel this request?" class="mt-12 md:mt-0"/>
    <div class="flex">
      <AppButton @click="$emit('cancel')">No</AppButton>
      <button
        @click="cancel()"
        class="py-1 px-3 border border-red-700 rounded-lg text-red-700 text-sm ml-2"
      >
        Yes, cancel
      </button>
    </div>
    <div class="flex justify-end mt-auto md:hidden">
      <img src="../assets/images/departing.svg" alt="" class="w-60"/>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader.vue";
import AppButton from "@/components/global/Button.vue";

export default {
  name: "Cancel",
  components: { AppButton, SectionHeader },
  methods: {
    cancel() {
      parent.postMessage({ id: 'cclan-cancel' }, "*");
    },
  }
}
</script>
