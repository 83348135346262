<template>
  <div class="h-full flex flex-col">
    <div class="px-6 lg:px-12 pb-8">
      <SectionHeader
        title="Provide numbers of people that trust you well"
        subtitle="Verification will be sent via WhatsApp, ensure you notify the owners of these numbers of incoming message from us"
      />

      <Message v-if="error" variant="error">{{ error }}</Message>

      <div class="flex flex-col space-y-4 mt-6">
        <div v-for="i in count" :key="i" class="space-y-4 border border-gray-300 px-4 py-4 rounded-lg">
          <AppInput placeholder="Enter full name" v-model="numbers[i - 1].name"/>
          <AppInput placeholder="Enter phone number" v-model="numbers[i - 1].phone"/>
        </div>
      </div>

      <label class="flex items-center mt-6">
        <input v-model="confirmed" class="mr-2" type="checkbox"> I have notified them
      </label>

      <div class="flex mt-8">
        <AppButton @click="next()">Continue</AppButton>
      </div>
    </div>

    <div class="flex justify-end mt-auto md:hidden">
      <img src="../../assets/images/whatsapp-phone-half.png" alt="" class="w-52"/>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppInput from "@/components/global/Input";
import AppButton from "@/components/global/Button";
import Message from "@/components/global/Message.vue";

export default {
  name: "FrequentlyCalledNumbers",
  components: { Message, AppButton, AppInput, SectionHeader },
  data() {
    return {
      error: '',
      confirmed: false,
      numbers: JSON.parse(JSON.stringify([...this.$store.state.eligibility.frequently_called_numbers])),
      count: Math.max(this.$store.state.eligibility.config.no_frequently_called_numbers, 1),
    }
  },
  created() {
    if (this.$store.state.eligibility.config.no_frequently_called_numbers < 1) return this.$emit('skip');
    const { recently, twoWeeks } = this.$store.getters['eligibility/lastApplied'];
    if (recently || twoWeeks) return this.$emit('skip');
  },
  methods: {
    next() {
      try {
        if (!this.valid() || !this.confirmed) {
          this.error = 'Please fill in all fields correctly, and check the box to confirm. Kindly note that you cannot add your own number';
          return;
        }
        this.$store.commit('eligibility/patch', { frequently_called_numbers: this.numbers });
        this.$emit('done');
      } catch (e) {
        this.$mixpanel.track('Error from frequently called numbers', {
          platform: this.$store.state.platform,
          request_id: this.$store.state.eligibility.request_id,
          error: e.message,
          stack: e.stack,
        });
      }
    },
    valid() {
      const phone = this.$store.state.eligibility.profile.phone;
      const cb = (n) => n.phone && n.phone !== phone;
      return this.numbers.slice(0, this.count).every(cb);
    }
  },
}
</script>
