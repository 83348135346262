<template>
  <div class="fill-height overflow-hidden md:p-4 lg:p-12 bg-gray-900 bg-opacity-70">
    <div class="grid grid-cols-12 overflow-hidden h-full max-w-7xl mx-auto md:rounded-lg lg:rounded-xl">
      <div
        class="hidden md:flex lg:flex md:col-span-3 lg:col-span-6 bg-blue-600 bg-center bg-white"
        :style="{
          background: `linear-gradient(to right, rgb(58 70 168 / 60%), rgb(94 80 151 / 60%)), url(${banner}) center no-repeat`,
          backgroundSize: 'cover',
          backgroundColor: '#fff'
        }"
      />
      <div class="col-span-12 md:col-span-9 lg:col-span-6 flex flex-col bg-white relative overflow-hidden">
        <img
          src="@/assets/images/pattern-up.png" alt="background pattern"
          class="absolute top-0 left-0 hidden md:block"
          style="width: 300px;"
        />
        <img
          src="@/assets/images/pattern-down.png" alt="background pattern"
          class="absolute bottom-0 right-0 hidden md:block"
          style="width: 300px"
        />
        <div class="h-full overflow-y-auto flex flex-col z-50">
          <template v-if="!cancelling">
            <slot></slot>
          </template>
          <Cancel v-else/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cancel from "@/components/Cancel.vue";

export default {
  name: "Layout",
  components: { Cancel },
  props: {
    banner: {
      type: String,
      default: 'https://images.unsplash.com/photo-1572666341285-c8cb9790ca50?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
    }
  },
  data() {
    return {
      cancelling: false,
    }
  },
  created() {
    this.$bus.$on('cancel', () => {
      this.cancelling = true;
    });
  },
  methods: {
    cancel() {
      this.cancelling = true;
    },
  },
}
</script>
