<template>
  <div v-if="loading" class="my-auto">
    <Loader
      v-if="loading === 'offers'" class="my-auto"
      :texts="['Getting offers..', 'Just leaving apapa traffic behind..', 'Almost done, collating final data..', 'One paper just fell, negotiating with the groundnut seller..']"
      :timeout="6000"
    />
    <Loader v-else :text="loading"/>
  </div>
  <Error v-else-if="!!error" :text="error" show-retry @retry="getOffers()" class="my-auto"/>
  <div v-else class="h-full flex flex-col">
    <template v-if="offer && offer.amount > 0">
      <div class="px-6 lg:px-12 pb-8 mt-10 md:mt-0">
        <SingleOffer
          v-if="view === 'single'" :offer="offer" @accept="handleAcceptOffer($event)" :accepting="accepting"
        />
        <GenericOffers
          v-if="view === 'generic'" :offer="offer" @accept="handleAcceptOffer($event)" :accepting="accepting"
        />
        <RentOffers
          v-else-if="view === 'rent'" :offer="offer" @accept="handleAcceptOffer($event)" :accepting="accepting"
        />
        <SchoolOffers
          v-else-if="view === 'school'" :offer="offer" @accept="handleAcceptOffer($event)" :accepting="accepting"
        />
        <EatOffers
          v-else-if="view === 'eat'" :offer="offer" @accept="handleAcceptOffer($event)" :accepting="accepting"
        />
      </div>
      <div class="flex justify-end mt-auto md:hidden">
        <img src="../../assets/images/happy-straight.jpg" alt="" class="w-full"/>
      </div>
    </template>
    <template v-else>
      <div class="text-center max-w-xs mx-auto flex flex-col items-center justify-center my-auto">
        <i class="fa fa-frown fa-5x text-gray-300"></i>
        <p class="mt-10 text-gray-600 font-semibold text-lg">We could not get any offer</p>
        <p class="text-gray-500 mt-3">Our partners believe your data is insufficient to offer you credit</p>
        <div class="mt-7 flex">
          <AppButton :loading="accepting" @click="payFull()">
            Pay full amount {{ $store.state.eligibility.request.amount | currency }}
          </AppButton>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ajax from "@/mixins/ajax";
import GenericOffers from "@/components/offers/GenericOffers.vue";
import EatOffers from "@/components/offers/EatOffers.vue";
import Error from "@/components/global/Error.vue";
import Loader from "@/components/global/Loader.vue";
import AppButton from "@/components/global/Button.vue";
import RentOffers from "@/components/offers/RentOffers.vue";
import SchoolOffers from "@/components/offers/SchoolOffers.vue";
import SingleOffer from "@/components/offers/SingleOffer.vue";
import { roundToNearestTen } from "@/lib/utils.js";

export default {
  name: "Offers",
  components: {
    SingleOffer,
    SchoolOffers,
    RentOffers,
    AppButton,
    Loader,
    Error,
    EatOffers,
    GenericOffers
  },
  mixins: [ajax],
  data() {
    return {
      view: 'generic',
      payment: { amount: 0, upfront: false },
      modal: '',
      accepting: false
    }
  },
  computed: {
    offer() {
      return this.$store.state.eligibility.offer;
    }
  },
  async created() {
    const { config } = this.$store.state.eligibility;
    if (!config.show_offers) return this.$emit('done');
    else if (!this.offer) {
      if (config.platform === 'rent-tenant') {
        this.handleGetOffers();
        return this.$emit('done');
      }
      await this.handleGetOffers();
    }
    if (config.single_offer) this.view = 'single';
    else if (config.platform === 'eat') this.view = 'eat';
    else if (config.platform === 'rent-tenant') this.view = 'rent';
    else if (config.platform === 'school') this.view = 'school';
    else this.view = 'generic';
  },
  methods: {
    async handleGetOffers() {
      if (`${ this.$store.state.eligibility.request.product_id }` === '29961') return this.getOffersHygeia();
      return await this.getOffers();
    },
    async getOffersHygeia() {
      const { request_id, token, questions, extra, request } = this.$store.state.eligibility;
      const payload = { request_id, token, questions };
      if (extra.is_anonymous) payload.is_anonymous = extra.is_anonymous;
      this.$http.post('/schoolportal/offers', payload);
      const total = request.amount;
      const duration = request.tenor;
      const monthly_repayment = roundToNearestTen(total / duration);
      const upfront = monthly_repayment * 2;
      const amount = total - upfront;
      const offer = {
        lender: 'Creditclan',
        upfront,
        monthly_repayment,
        total_repayment: amount,
        amount,
        duration: duration - 1,
      };
      this.$store.commit('eligibility/patch', { offer });
    },
    async getOffers() {
      this.$mixpanel.track('Getting offers', {
        platform: this.$store.state.eligibility.config.platform,
        request_id: this.$store.state.eligibility.request_id
      });
      this.setLoading('offers', true);
      try {
        const {
          request_id, token, questions, stage, extra, instant_fulfilment,
          config: { always_show_offers, first_month_upfront, return_offer }
        } = this.$store.state.eligibility;
        const payload = { request_id, token, questions };
        if (extra.is_anonymous) payload.is_anonymous = extra.is_anonymous;
        if (instant_fulfilment) payload.instant_fulfilment = instant_fulfilment;
        const { data } = await this.$http.post('/schoolportal/offers', payload);
        if (!data.status) {
          const message = data?.message || 'An error occurred while getting offers, please try again or contact support';
          this.$mixpanel.track('Error while getting offers', {
            platform: this.$store.state.eligibility.config.platform,
            request_id: this.$store.state.eligibility.request_id,
            message
          });
          return this.setError(message, true);
        }
        let offer = data?.offers?.length ? data.offers[0] : null;
        if (return_offer) return this.returnOffer(offer);
        offer = {
          ...offer,
          upfront: roundToNearestTen(+(`${ offer.upfront }`.replace(/,/gi, ''))),
          amount: roundToNearestTen(+(`${ offer.amount }`.replace(/,/gi, ''))),
          monthly_repayment: roundToNearestTen(+(`${ offer.monthly_repayment }`.replace(/,/gi, ''))),
        };
        if (always_show_offers && !offer.amount) {
          const price = offer.amount + offer.upfront;
          const upfront = roundToNearestTen(price * .5);
          const total_repayment = price - upfront;
          const duration = 3;
          offer = {
            ...offer,
            upfront,
            amount: total_repayment,
            total_repayment,
            monthly_repayment: roundToNearestTen(total_repayment / duration),
            duration,
          }
        }
        if (first_month_upfront) {
          const total = offer.amount + offer.upfront;
          const duration = offer.duration;
          const monthly_repayment = roundToNearestTen(total / duration);
          const upfront = monthly_repayment;
          const amount = total - upfront;
          offer = {
            ...offer,
            upfront,
            monthly_repayment,
            total_repayment: amount,
            amount,
            duration: duration - 1,
          }
        }
        this.$mixpanel.track(offer?.amount ? 'Got offer' : `Got no offer`, {
          platform: this.$store.state.eligibility.config.platform,
          request_id: this.$store.state.eligibility.request_id,
          offer
        });
        this.$store.commit('eligibility/patch', { offer });
        if (stage !== 'offers') await this.$store.dispatch('eligibility/updateStage', { stage: 'offers' });
      } catch (e) {
        const message = e?.response?.data?.message || 'An error occurred while getting offers, please try again or contact support';
        this.setRetry(() => this.getOffers());
        this.setError(message, true);
        this.$mixpanel.track('Error while getting offers', {
          platform: this.$store.state.eligibility.config.platform,
          request_id: this.$store.state.eligibility.request_id,
          message
        });
      }
      this.setLoading('');
    },
    async handleAcceptOffer(offer) {
      const accepted = await this.acceptOffer(offer);
      if (accepted) {
        if (+offer.upfront) {
          const payment = { amount: offer.upfront, upfront: true };
          this.$store.commit('eligibility/patch', { payment });
        }
        this.$mixpanel.track('Accepted offer', {
          platform: this.$store.state.eligibility.config.platform,
          request_id: this.$store.state.eligibility.request_id,
          offer
        });
        this.next();
      }
    },
    async acceptOffer(offer) {
      this.accepting = true;
      try {
        const { request_id, token, request } = this.$store.state.eligibility;
        const duration = `${ request.product_id }` === '29961' ? duration + 1 : duration;
        const amount = `${ request.product_id }` === '29961' ? +offer.loan_amount + +offer.upfront : offer.loan_amount;
        const payload = {
          request_id, token, amount,
          repayment_amount: offer.repayment_amount,
          duration: offer.duration,
          tenor_type: offer.tenor_type,
          upfront: offer.upfront,
          interest: offer.interest,
        };
        const { data } = await this.$http.post('https://mobile.creditclan.com/webapi/v1/loan/modify_offer', payload);
        this.createContract(payload);
        await this.$store.dispatch('eligibility/updateStage', {
          stage: 'offer-selected', offer_selected: offer
        });
        if (data.status) this.$store.commit('eligibility/patch', { offer });
        this.accepting = false;
        return data.status;
      } catch (err) {
        this.setRetry(() => this.acceptOffer(offer));
        const message = err?.response?.data?.message || 'An error occurred, please try again';
        this.setError(message);
        this.$mixpanel.track('Error while accepting offer', {
          platform: this.$store.state.eligibility.config.platform,
          request_id: this.$store.state.eligibility.request_id,
          message
        });
      }
      this.accepting = false;
      return false;
    },
    async createContract(payload) {
      try {
        await this.$http.post('https://mobile.creditclan.com/webapi/v1/loan/create_contract', payload);
      } catch (e) {
        const message = e?.response?.data?.message || 'An error occurred, please try again';
        this.$mixpanel.track('Error while creating contract', {
          platform: this.$store.state.eligibility.config.platform,
          request_id: this.$store.state.eligibility.request_id,
          message
        });
      }
    },
    async payFull() {
      const { request: { amount } } = this.$store.state.eligibility;
      await this.acceptOffer({ amount: 0, duration: 1, tenor_type: 1 });
      const payment = { amount, upfront: false };
      this.$store.commit('eligibility/patch', { payment });
      this.next();
    },
    next() {
      this.$emit('done');
    },
    async returnOffer(offer) {
      await this.cancelRequest();
      parent.postMessage({ id: 'cclan-offer', data: { offer } }, '*');
      parent.postMessage({ id: 'cclan-close' }, '*');
    },
    async cancelRequest() {
      const { request_id } = this.$store.state.eligibility;
      await this.$http.post(`/cancel/loan_request`, { request_id });
    }
  },
}
</script>
