<template>
  <div class="h-full flex flex-col">
    <div class="px-6 lg:px-12 pb-8">
      <div v-if="!change">
        <SectionHeader
          title="Is this your Home address?"
          :subtitle="`${home_address.address}${getStateName() ? `, ${getStateName()}` : ''}`"
        />
        <div class="flex flex-col gap-3">
          <div @click="next()" class="border px-5 py-3 cursor-pointer rounded-lg flex items-center justify-between">
            Yes <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
          </div>
          <div
            @click="change = true"
            class="border px-5 py-3 cursor-pointer rounded-lg flex items-center justify-between"
          >
            No <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
          </div>
        </div>
      </div>
      <div v-else>
        <SectionHeader title="Enter your home address"/>
        <ValidationObserver ref="observer">
          <form class="flex flex-col space-y-3">
            <ValidationProvider name="address" rules="required" v-slot="{ errors }">
              <AppTextArea placeholder="Home/Street address" v-model="values.address" :errors="errors"/>
            </ValidationProvider>
            <ValidationProvider name="state" rules="required" v-slot="{ errors }">
              <AppSelect v-model="values.state" :errors="errors" @change="handleStateChange($event)">
                <option value="">State</option>
                <option v-if="!states.length" value="" disabled>Loading</option>
                <option v-for="state in states" :key="state.value" :value="state.value">
                  {{ state.text }}
                </option>
              </AppSelect>
            </ValidationProvider>
            <ValidationProvider name="lga" rules="required" v-slot="{ errors }">
              <AppSelect v-model="values.lga" :errors="errors">
                <option value="">LGA</option>
                <option v-if="values.state && !lgas.length" value="" disabled>Loading</option>
                <option v-for="lga in lgas" :key="lga.value" :value="lga.value">{{ lga.text }}</option>
              </AppSelect>
            </ValidationProvider>
          </form>
          <div class="mt-10 flex items-center">
            <button
              v-if="$store.state.eligibility.home_address.address" @click="change = false"
              class="border border-gray-500 text-gray-700 px-5 py-2 mr-2 rounded-lg text-sm hover:bg-gray-50"
            >
              <i class="fa fa-chevron-left mr-2"></i> Back
            </button>
            <AppButton @click="save()">Continue</AppButton>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <div class="flex justify-end mt-auto md:hidden">
      <img src="../../assets/images/whatsapp-phone-half.png" alt="" class="w-52"/>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppTextArea from "@/components/global/TextArea";
import AppSelect from "@/components/global/Select";
import AppButton from "@/components/global/Button";
import states from "@/lib/states";

export default {
  name: "ConfirmAddress",
  components: { AppButton, AppSelect, AppTextArea, SectionHeader },
  data() {
    return {
      home_address: { ...this.$store.state.eligibility.home_address },
      change: false,
      values: {
        address: '',
        state: '',
        lga: ''
      },
      states,
      lgas: []
    }
  },
  created() {
    const { show_address } = this.$store.state.eligibility.config;
    const { recently, twoWeeks, oneMonth } = this.$store.getters["eligibility/lastApplied"];
    if (recently || twoWeeks || oneMonth || !show_address) return this.$emit("skip");
    if (!this.home_address.address) this.change = true;
  },
  methods: {
    next() {
      this.$emit("done");
    },
    async save() {
      if (!(await this.$refs.observer.validate())) return;
      this.$store.commit("eligibility/home_address", this.values);
      this.next();
    },
    handleStateChange() {
      this.values.lga = null;
      this.lgas = [];
      this.getStateLgas();
    },
    async getStateLgas() {
      const res = await this.$http.get(`https://mobile.creditclan.com/webapi/v1/states/${ this.values.state }/lgas`);
      this.lgas = res.data.data.map(lga => ({ text: lga.name, value: lga.id }));
    },
    getStateName() {
      return states.find((s) => s.value.toString() === this.home_address?.state_id?.toString())?.text;
    },
  },
};
</script>
