<template>
  <div class="h-full flex flex-col">
    <div class="px-6 lg:px-12 pb-8">
      <div v-if="!form">
        <SectionHeader title="Looks like you're active on Instagram or Facebook"/>
        <div class="flex flex-col space-y-3">
          <div
            @click="form = true"
            class="border px-5 py-3 cursor-pointer rounded-lg flex justify-between items-center"
          >
            Yes, provide social media handles
            <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
          </div>
          <div
            @click="$emit('done')"
            class="border px-5 py-3 cursor-pointer rounded-lg flex justify-between items-center"
          >
            No skip
            <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
          </div>
        </div>
      </div>
      <div v-else>
        <SectionHeader title="Provide social media handles"/>
        <div class="mt-6">
          <ValidationObserver ref="observer">
            <ValidationProvider name="facebook" rules="required" v-slot="{ errors }">
              <AppInput placeholder="Facebook username" v-model="values.facebook" :errors="errors"/>
            </ValidationProvider>
            <ValidationProvider name="instagram" rules="required" v-slot="{ errors }">
              <AppInput placeholder="Instagram handle" v-model="values.instagram" class="mt-4" :errors="errors"/>
            </ValidationProvider>
            <div class="mt-8 flex items-center">
              <button
                @click="form = false"
                class="border border-gray-500 text-gray-700 px-3 py-1 mr-2 rounded-lg text-sm"
              >
                <i class="fa fa-chevron-left mr-2"></i> Back
              </button>
              <AppButton @click="submit()">Continue</AppButton>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <div class="flex justify-end mt-auto md:hidden">
      <img src="../../assets/images/whatsapp-phone-half.png" alt="" class="w-52"/>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppInput from "@/components/global/Input";
import AppButton from "@/components/global/Button";

export default {
  name: "SocialHandles",
  components: { AppButton, AppInput, SectionHeader },
  data() {
    return {
      form: false,
      values: { ...this.$store.state.eligibility.social },
    };
  },
  created() {
    return this.$emit('skip');
    // const { recently, twoWeeks, oneMonth, threeMonths } = this.$store.getters['eligibility/lastApplied'];
    // if (recently || twoWeeks || oneMonth || threeMonths) return this.$emit('done')
  },
  methods: {
    async submit() {
      if (!await this.$refs.observer.validate()) return;
      this.$store.commit('eligibility/patch', { social: this.values });
      this.$emit('done');
    }
  }
}
</script>
