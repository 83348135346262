import { addMinutes, differenceInDays } from "date-fns";
import http from "@/plugins/http.js";
import axios from "axios";

const state = {
  request: {
    amount: 0,
    duration: 0,
    tenor_type: 2
  },
  profile: {
    user_id: null,
    full_name: '',
    email: '',
    phone: '',
    profile_image: ''
  },
  next_of_kin: {
    full_name: '',
    email: '',
    phone: '',
    address: '',
    relationship: '',
  },
  account: {
    bvn: null,
    account_name: '',
    account_number: '',
    bank_id: '',
    bank_code: '',
    okra_id: null,
  },
  home_address: {
    address: '',
    state_id: '',
  },
  work: {
    monthly_income: '',
    work_sector: '',
    occupation_id: '',
    address: '',
    start_month: '',
    start_year: '',
    company_name: '',
    official_email: '',
    work_email_verified: '',
  },
  identity: {
    nin: ''
  },
  frequently_called_numbers: [{ name: '', phone: '' }, { name: '', phone: '' }],
  social: {
    facebook: '',
    instagram: '',
  },
  has_data: false,
  questions: [],
  request_id: null,
  new_customer: false,
  token: null,
  offer: null,
  stage: '',
  bs: {
    has_online_banking: 0,
    response: null
  },
  config: {
    no_frequently_called_numbers: 2,
    analyze_bank_statement: true,
    tokenize_card: true,
    show_offers: true,
    show_questions: true,
    show_bank_account: true,
    match_account_with_bvn: false,
    show_work_information: false,
    verify_email: false,
    show_nin: true,
    scan_whatsapp: false,
    show_signature: false,
    platform: null,
    bs_name_match: false,
    account_name_match: false,
    recommended_upfront_cap: false,
    always_show_offers: false,
    show_address: false,
    show_profile: false,
    first_month_upfront: false,
    single_offer: false,
    can_change_repayment_frequency: false,
    show_income: false,
    show_nok: false,
    modify_offer_beyond_limits: false,
    can_modify_offer: false,
    verify_work_email: false,
    show_picture: false,
    return_offer: false,
  },
  extra: {},
  last_application_date: null,
  timer_end: addMinutes(new Date(Date.now()), 5),
  timer_start: new Date(Date.now()),
  email_verified: false,
  lender: null,
  whatsapp_id: null,
  banks_mono: null,
  payment: null,
  documents: [],
  instant_fulfilment: false,
};

const getters = {
  bankName: state => {
    return state.banks_mono?.find(bank => bank.id === state.account.bank_id)?.name || ''
  },
  lastApplied: state => {
    if (!state.last_application_date) return {
      recently: false,
      twoWeeks: false,
      oneMonth: false,
      threeMonths: false
    };
    const date = new Date(state.last_application_date);
    const today = new Date(Date.now());
    const difference = differenceInDays(today, date);
    return {
      recently: difference <= 2,
      twoWeeks: difference > 2 && difference <= 14,
      oneMonth: difference > 14 && difference <= 30,
      threeMonths: difference > 30 && difference <= 60
    };
  }
};
const mutations = {
  patch: (state, data) => {
    Object.keys(data).forEach(key => {
      state[key] = data[key];
    });
  },
  profile: (state, data) => {
    Object.keys(data).forEach(key => {
      state.profile[key] = data[key];
    });
  },
  home_address: (state, data) => {
    Object.keys(data).forEach(key => {
      state.home_address[key] = data[key];
    });
  },
  work: (state, data) => {
    Object.keys(data).forEach(key => {
      state.work[key] = data[key];
    });
  },
  account: (state, data) => {
    Object.keys(data).forEach(key => {
      state.account[key] = data[key];
    });
  },
  bs: (state, data) => {
    Object.keys(data).forEach(key => {
      state.bs[key] = data[key];
    });
  },
  social: (state, data) => {
    Object.keys(data).forEach(key => {
      state.social[key] = data[key];
    });
  },
  config: (state, data) => {
    Object.keys(data).forEach(key => {
      state.config[key] = data[key];
    });
  },
};
const actions = {
  async postData({ state }, data) {
    const payload = { token: state.token, amount: state.request.amount, };
    if (data.type === 'phone') payload.phone = state.profile.phone;
    if (data.type === 'nin') payload.nin = state.identity.nin;
    if (data.type === 'bvn') payload.bvn = state.account.bvn;
    const res = await http.post('/customer/getdata_market', { ...payload, ...data });
    return res.data;
  },
  async updateStage({ state, commit }, payload) {
    const { token, request_id } = state;
    await http.post('https://mobile.creditclan.com/api/v3/request/update/stage', {
      token, request_id, ...payload
    });
    commit('patch', { stage: payload.stage });
  },
  async createRequest({ state, commit, dispatch }) {
    try {
      const {
        request, profile, frequently_called_numbers: fcn, account, work, home_address, social, extra, next_of_kin,
        config: { platform }
      } = state;
      const eligibility_link = location.href;
      let url = '/loan/apply_for_bnpl';
      if (platform === 'rent-tenant') url = '/loan/apply_for_rent';
      const payload = {
        frequently_called_numbers: [...fcn].filter(n => n.phone), profile, work, request, next_of_kin,
        home_address, social, eligibility_link, ...extra
      }
      if (account?.account_name) payload.account = account;
      const { data } = await http.post(url, payload);
      if (!data.status) {
        return Error(data.message || 'An error occurred while creating your request, please try again');
      }
      commit('patch', { request_id: data.dd });
      await dispatch('updateStage', { stage: 'created' });
      await dispatch('saveCreditclanRequestId');
      return true;
    } catch (e) {
      return Error(e.response ? e.response.data.message : e.message || 'An error occurred, please try again');
    }
  },
  async saveCreditclanRequestId({ state }) {
    const { config: { platform }, extra, request_id, profile: { profile_image } } = state;
    try {
      if (platform === 'shop-global') {
        if (!extra.global_request_id) return;
        await axios.patch('https://sellbackend.creditclan.com/merchantclan/public/index.php/api/global/request', {
          creditclan_request_id: request_id, global_request_id: extra.global_request_id
        });
      }
      if (platform === 'shop-power') {
        await axios.patch(`https://sellbackend.creditclan.com/merchantclan/public/index.php/api/request/${ extra.power_merchant_request_id }`, {
          creditclan_request_id: request_id
        });
      }
      if (platform === 'shop-merchant') {
        await axios.patch(`https://sellbackend.creditclan.com/merchantclan/public/index.php/api/request/${ extra.merchant_request_id }`, {
          creditclan_request_id: request_id
        });
      }
      if (platform === 'rent-tenant') {
        await axios.post(`https://wema.creditclan.com/api/v3/rent/update_cc_request`, {
          rent_id: extra.rent_id, creditclan_request_id: request_id
        });
      }
      if (platform === 'school-parent') {
        await axios.post(`https://sellbackend.creditclan.com/parent/index.php/external/update_cc_request`, {
          creditclan_request_id: request_id, parent_request_id: extra.parent_request_id, profile_image
        });
      }
      if (platform === 'school-teacher') {
        await axios.post(`https://sellbackend.creditclan.com/parent/index.php/external/update_cc_request`, {
          creditclan_request_id: request_id, teacher_request_id: extra.teacher_request_id
        });
      }
      if (platform === 'merchant') {
        await axios.post(`https://sellbackend.creditclan.com/parent/index.php/external/update_cc_request`, {
          creditclan_request_id: request_id, merchant_request_id: extra.merchant_request_id
        });
      }
    } catch (e) {
      console.log({ e });
    }
  }
};

const eligibility = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default eligibility;
