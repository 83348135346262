<template>
  <div v-if="overlay" class="my-auto w-full">
    <RandomQuestions @done="overlay = ''"/>
  </div>
  <div v-else class="h-full flex flex-col">
    <div class="pt-6 lg:py-10 h-full flex flex-col">
      <div class="flex justify-between mb-8 px-6 lg:px-12">
        <button
          @click="popView()"
          class="w-8 h-8 rounded-full text-gray-500 bg-gray-50 border text-sm flex items-center justify-center"
          :class="{ 'pointer-events-none opacity-0': history.length <= 1 || loading }"
        >
          <i class="fa fa-chevron-left"></i>
        </button>
        <button
          @click="cancel()" v-if="inIframe"
          class="w-8 h-8 rounded-full flex justify-center items-center border border-red-500 text-red-500 hover:bg-red-50"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="h-full flex flex-col md:h-auto md:my-auto">
        <div v-if="loading" class="px-6 lg:px-12 my-auto">
          <Loader :text="loading"/>
        </div>
        <Error v-else-if="error" :text="error" :show-retry="!!retryCb" @retry="retry()"/>
        <template v-else>
          <VerifyEmail
            v-if="view === 'verify-email'"
            @done="pushView('questions-alert')"
            @skip="replaceView('questions-alert')"
          />
          <QuestionsAlert
            v-if="view === 'questions-alert'"
            @done="replaceView('initial-questions')"
            @skip="replaceView('initial-questions')"
          />
          <RandomQuestions
            v-if="view === 'initial-questions'"
            :max="1"
            @done="replaceView('picture')"
            @skip="replaceView('picture')"
          />
          <Picture
            v-if="view === 'picture'"
            @done="pushView('confirm-address')"
            @skip="replaceView('confirm-address')"
          />
          <ConfirmAddress
            v-if="view === 'confirm-address'"
            @done="pushView('income')"
            @skip="replaceView('income')"
          />
          <Income
            v-if="view === 'income'"
            @done="pushView('profile')"
            @skip="replaceView('profile')"
          />
          <Profile
            v-if="view === 'profile'"
            @done="pushView('work')"
            @skip="replaceView('work')"
          />
          <WorkInformation
            v-if="view === 'work'"
            @done="pushView('verify-work-email')"
            @skip="replaceView('verify-work-email')"
          />
          <VerifyWorkEmail
            v-if="view === 'verify-work-email'"
            @done="pushView('work-identity')"
            @skip="replaceView('work-identity')"
          />
          <WorkIdentity
            v-if="view === 'work-identity'"
            @done="pushView('next-of-kin')"
            @skip="replaceView('next-of-kin')"
          />
          <NextOfKin
            v-if="view === 'next-of-kin'"
            @done="pushView('social-handles')"
            @skip="replaceView('social-handles')"
          />
          <SocialHandles
            v-if="view === 'social-handles'"
            @done="pushView('fcn')"
            @skip="replaceView('fcn')"
          />
          <FrequentlyCalledNumbers
            v-if="view === 'fcn'"
            @done="pushView('nin')"
            @skip="replaceView('nin')"
          />
          <NationalIdentityNumber
            v-if="view === 'nin'"
            @view="pushView($event)"
            @done="runFaceMatch(); pushView('account')"
            @skip="runFaceMatch(); replaceView('account')"
          />
          <BankAccount
            v-if="view === 'account'"
            @done="pushView('bank-statement')"
            @skip="replaceView('bank-statement')"
          />
          <BankStatement
            v-if="view === 'bank-statement'"
            @back="popView()"
            @done="finish()"
            @skip="finish()"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Picture from "@/components/sections/Picture.vue";
import Loader from "@/components/global/Loader.vue";
import view from "@/mixins/view.js";
import ajax from "@/mixins/ajax.js";
import Error from "@/components/global/Error.vue";
import RandomQuestions from "@/components/sections/RandomQuestions.vue";
import ConfirmAddress from "@/components/sections/ConfirmAddress.vue";
import WorkInformation from "@/components/sections/WorkInformation.vue";
import SocialHandles from "@/components/sections/SocialHandles.vue";
import FrequentlyCalledNumbers from "@/components/sections/FrequentlyCalledNumbers.vue";
import NationalIdentityNumber from "@/components/sections/NationalIdentityNumber.vue";
import Income from "@/components/sections/Income.vue";
import BankAccount from "@/components/sections/BankAccount.vue";
import BankStatement from "@/components/sections/BankStatement.vue";
import QuestionsAlert from "@/components/sections/QuestionsAlert.vue";
import VerifyEmail from "@/components/sections/VerifyEmail.vue";
import Profile from "@/components/sections/Profile.vue";
import VerifyWorkEmail from "@/components/sections/VerifyWorkEmail.vue";
import NextOfKin from "@/components/sections/NextOfKin.vue";
import WorkIdentity from "@/components/sections/WorkIdentity.vue";

export default {
  name: "PreAnalysis",
  components: {
    WorkIdentity,
    NextOfKin,
    VerifyWorkEmail,
    Profile,
    VerifyEmail,
    QuestionsAlert,
    BankStatement,
    BankAccount,
    Income,
    NationalIdentityNumber,
    FrequentlyCalledNumbers,
    SocialHandles,
    WorkInformation,
    ConfirmAddress,
    RandomQuestions,
    Error,
    Loader,
    Picture,
  },
  mixins: [view, ajax],
  data: () => ({
    inIframe: false
  }),
  created() {
    this.getBanks();
    this.inIframe = window.self !== window.top;
    this.init();
    if (this.$store.getters['eligibility/lastApplied'].recently) return this.finish();
    this.pushView('verify-email');
    this.$bus.$on('time-finished', () => {
      this.finish();
    });
  },
  methods: {
    async init() {
      if (!this.$store.state.eligibility.request_id) {
        await this.$store.dispatch('eligibility/createRequest');
      }
    },
    async runFaceMatch(retries = 1) {
      const { has_data } = this.$store.state.eligibility;
      if (!has_data) return;
      if (retries >= 3) return;
      try {
        const res = await this.$http.post('/customer/facematch', {
          token: this.$store.state.eligibility.token
        });
        if (!res?.data?.people_id) return await this.runFaceMatch(++retries);
      } catch (e) {
        console.log(e);
        return await this.runFaceMatch(++retries);
      }
    },
    async finish() {
      this.modal = '';
      await this.saveUserData();
      if (!this.$store.state.eligibility.request_id) {
        this.setLoading('Submitting request..');
        const res = await this.$store.dispatch('eligibility/createRequest');
        this.setLoading('');
        if (res instanceof Error) {
          this.setError(res.message || 'Unspecified error');
          this.$mixpanel.track('Error while creating request', {
            platform: this.$store.state.eligibility.config.platform,
            request_id: this.$store.state.eligibility.request_id,
            error: res?.message || 'Unspecified error'
          });
          return;
        }
      }
      if (this.$store.state.eligibility.request_id) this.$emit('done');
    },
    async saveUserData() {
      this.setLoading('Submitting request..', true);
      try {
        const {
          request, profile, frequently_called_numbers: fcn, account, work,
          home_address, social, token, next_of_kin, documents
        } = this.$store.state.eligibility;
        await this.$http.post('/customer/save_market_data', {
          frequently_called_numbers: [...fcn].filter(n => n.phone),
          profile, account, work, request, home_address, social, token, next_of_kin,
          documents: documents.map(doc => doc.url)
        });
      } catch (e) {
        this.setError(e.response ? e.response.data.message : e.message || 'An error while saving your data, please try again');
        this.setRetry(() => this.saveUserData());
      }
      this.setLoading('');
    },
    cancel() {
      this.$bus.$emit('cancel');
    },
    async getBanks() {
      if (this.$store.state.eligibility.banks_mono) return;
      const res = await this.$http.get('https://mobile.creditclan.com/webapi/v1/banks_mono');
      const banks_mono = res.data.data;
      this.$store.commit('eligibility/patch', { banks_mono })
    }
  }
};
</script>
