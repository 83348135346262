<template>
  <div class="px-6 lg:px-12 pb-8">
    <Loader v-if="loading !== ''" :text="loading" class="my-auto"/>
    <Error v-else-if="error" text="Whatsapp number not correct" show-retry @retry="getCode"/>
    <div v-else>
      <template v-if="!whatsapp_id">
        <SectionHeader
          title="Confirm your whatsapp"
          :subtitle="`Scan the QR Code below with the whatsapp connected to ${$store.state.eligibility.profile.phone} to confirm your ownership of the device`"
        />
        <div>
          <div class="inline-block border rounded-lg overflow-hidden">
            <img :src="image" alt="qr code"/>
          </div>
        </div>
        <button
          @click="modals.how = true"
          class="mt-6 border-b-2 border-blue-400 text-blue-400 hover:text-blue-600 hover:border-blue-600"
        >
          Show me how
        </button>
      </template>
      <template v-else>
        <SectionHeader title="Whatsapp connected" subtitle="QR code has been successfully scanned"/>
        <AppButton @click="$emit('done')" class="mt-5">Continue</AppButton>
      </template>

      <Modal v-if="modals.how">
        <div class="flex items-center justify-between mb-10">
          <h5 class="text-2xl">How to scan with your Whatsapp</h5>
          <div>
            <button
              @click="modals.how = false"
              class="w-8 h-8 rounded-full flex justify-center items-center border border-red-500 text-red-500 hover:bg-red-50"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
        <img src="@/assets/images/scan-qr-code.gif" class="w-48 rounded-md" alt=""/>
        <div class="mt-10">
          <AppButton @click="modals.how = false" size="lg">Got it</AppButton>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/global/Button";
import ajax from "@/mixins/ajax";
import Loader from "@/components/global/Loader";
import SectionHeader from "@/components/global/SectionHeader";
import Error from "@/components/global/Error";
import Modal from "@/components/global/Modal.vue";

export default {
  name: "ConnectWhatsapp",
  components: { Modal, Error, SectionHeader, Loader, AppButton },
  data() {
    return {
      image: '',
      transaction_id: null,
      qrStatus: null,
      modals: {
        how: false
      }
    }
  },
  mixins: [ajax],
  created() {
    const { whatsapp_id, config: { scan_whatsapp } } = this.$store.state.eligibility;
    if (whatsapp_id || !scan_whatsapp) return this.$emit('done');
    if (!this.qrStatus) this.getCode();
  },
  methods: {
    async getCode() {
      this.$store.commit('eligibility/patch', { whatsapp_id: null });
      const { full_name, phone } = this.$store.state.eligibility.profile;
      this.setLoading('Getting QR Code..');
      const data = { name: full_name, phone };
      const res = await this.$http.post('https://whatsapp.creditclan.com/api/customer/generate/qr', data);
      this.setLoading('');
      this.image = res.data.data;
      this.transaction_id = res.data.reference;
      await this.checkStatus();
    },
    async checkStatus() {
      const res = await this.$http.get(`https://whatsapp.creditclan.com/api/status/${ this.transaction_id }`);
      const { image, qrStatus } = res.data.response;
      this.image = image;
      if (qrStatus === 0) return setTimeout(() => this.checkStatus(), 2000);
      if (qrStatus === -1) return this.setError('Whatsapp number not correct', true);
      this.$store.commit('eligibility/patch', { whatsapp_id: this.transaction_id });
      await this.save();
    },
    async save() {
      try {
        this.setLoading('Saving..');
        const { token } = this.$store.state.eligibility;
        await this.$http.post('https://mobile.creditclan.com/api/v3/whatsapp/scanner', {
          token, whatsapp_id: this.transaction_id,
        });
        this.$emit('done');
      } catch (e) {
        console.log(e);
      }
      this.setLoading('');
    }
  },
  computed: {
    whatsapp_id() {
      return this.$store.state.eligibility.whatsapp_id;
    }
  }
}
</script>
