<template>
  <div v-if="overlay" class="my-auto w-full">
    <RandomQuestions v-if="overlay === 'random-questions'" @done="overlay = ''"/>
    <div v-if="overlay === 'contact'" class="px-6 lg:px-12 pb-8 flex flex-col justify-center items-center text-center">
      <i class="fa fa-exclamation-circle fa-4x text-red-400 mb-10"></i>
      <h4 class="text-lg font-semibold">We could not analyze your bank statement</h4>
      <p class="mt-4">
        Someone from our support team will reach out to you to provide further assistance. You can also reach us on
        +2349155577731
      </p>
      <AppButton @click="tryAgain()" variant="outline-danger" class="mt-8">Try again</AppButton>
    </div>
  </div>
  <div v-else class="h-full flex flex-col">
    <!-- Loading -->
    <Loader v-if="loading" :text="loading" class="px-6 lg:px-12 pb-8"/>
    <div v-else class="h-full flex flex-col">
      <div class="px-6 lg:px-12 pb-8">
        <!-- Upload -->
        <SectionHeader :title="title"/>
        <Message v-if="error" class="mb-6" variant="error">{{ error }}</Message>
        <label
          class="w-full py-6 px-6 border bg-gray-50 text-gray-600 rounded-lg flex flex-col justify-center cursor-pointer"
        >
          <span class="flex items-center">
            <input accept=".pdf" hidden name="file" type="file" @change="handleFileChange($event)">
            <i class="fa fa-file-pdf fa-2x text-red-500 mr-3"></i>
            <template v-if="!file">
              Click to select pdf file
            </template>
            <template v-else>
              <span class="truncate mr-2">{{ file.name }}</span>
              <i class="fa fa-check-circle fa-2x text-green-500 ml-auto"></i>
            </template>
          </span>
        </label>
        <div v-if="file" class="mt-7">
          <AppInput v-model="values.password" placeholder="Password"/>
          <small
            class="flex items-center leading-tight bg-yellow-50 rounded-lg py-3 px-4 text-yellow-600 mt-4 border border-yellow-100"
          >
            <i class="fa fa-exclamation-circle mr-2"></i>
            Enter password above if pdf file is secured, otherwise, leave password field empty
          </small>
          <div class="flex mt-6">
            <AppButton :disabled="!file || !values.type" @click="upload()">Analyse</AppButton>
          </div>
        </div>
        <div v-if="hasOptions" class="mt-6">
          <button class="text-gray-600 text-base" type="button" @click="$emit('options')">
            <span class="text-blue-600">I don't have </span>my bank statement file
          </button>
        </div>
      </div>
      <div class="flex justify-end mt-auto md:hidden">
        <img src="../../assets/images/savings.svg" alt="" class="w-60"/>
      </div>
    </div>
  </div>
</template>

<script>
import banks from "@/lib/banks";
import Loader from "@/components/global/Loader";
import AppInput from "@/components/global/Input";
import AppButton from "@/components/global/Button";
import SectionHeader from "@/components/global/SectionHeader";
import view from '@/mixins/view';
import RandomQuestions from "@/components/sections/RandomQuestions";
import Message from "../global/Message";
import ajax from "@/mixins/ajax.js";

export default {
  name: "BSLocal",
  components: { Message, RandomQuestions, SectionHeader, AppButton, AppInput, Loader },
  mixins: [view, ajax],
  props: {
    title: {
      type: String,
      default: 'Upload your bank statement'
    },
    hasOptions: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      banks: banks,
      file: null,
      values: {
        password: '',
        type: '1',
      },
      url: null
    };
  },
  methods: {
    handleFileChange(e) {
      this.values.password = '';
      this.file = e.target.files[0];
    },
    upload() {
      this.submit();
    },
    async submit() {
      this.overlay = 'random-questions';
      this.setLoading('Processing bank statement..');
      const payload = {
        statement: this.file, ...this.values,
        full_name: this.$store.state.statement.payload.account_name
      };
      const fd = new FormData();
      Object.keys(payload).forEach(key => fd.append(key, payload[key]));
      try {
        const { data } = await this.$http.post('https://app.bankstatement.ai/analytics/widget', fd);
        if (data.transaction_id) {
          this.setLoading('Analyzing..');
          await this.checkStatus(data.transaction_id);
        } else {
          this.setError('Something went wrong, please check pdf file or try again', true);
        }
      } catch (err) {
        this.setError(err.response ? err.response.data.message : 'An error occurred, please try again', true);
      }
    },
    async checkStatus(transaction_id, n = 0) {
      if (n === 16) {
        return this.setError('Something went wrong, please check pdf file or try again', true);
      }
      try {
        const { data } = await this.$http.get(
          `https://app.bankstatement.ai/analytics/status/${ transaction_id }`,
          {
            maxBodyLength: Infinity,
            maxContentLength: Infinity
          }
        );
        const { analytics_pdf, extraction_error } = data;
        if (extraction_error) return this.setError(extraction_error, true);
        if (analytics_pdf) {
          return this.$emit('done', transaction_id);
        }
        setTimeout(() => this.checkStatus(transaction_id, ++n), 2000);
      } catch (e) {
        const message = e?.response?.data?.error || e.message || 'An error occurred, please try again!';
        this.setError(message, true);
        this.setOverlay('contact');
      }
    },
    tryAgain() {
      this.overlay = '';
      this.file = null;
      this.setError('');
    }
  },
}
</script>
